<template>
  <div class="home-page">
    <div class="container">
      <a
        class="logout-link"
        @click.prevent="logout">
        {{ $t('navbar.navs.logout') }}
      </a>
      <div class="columns is-mobile">
        <div
          v-if="!role"
          class="column has-text-centered is-size-4">
          {{ $t('common.loading') }}
        </div>
        <template v-else>
          <div class="column is-half">
            <img
              :src="require('@images/logo_black.png')"
              class="logo">
            <div class="home-header">
              {{ $t('home.header', { user: userName }) }}
            </div>
            <div class="home-message">
              {{ $t('home.message') }}
            </div>
            <div class="main-pages">
              <b-button
                v-for="nav in mainPages"
                :key="`main_page_nav_${nav.label}`"
                class="is-primary"
                tag="router-link"
                :to="nav.path">
                {{ nav.label }}
              </b-button>
            </div>
            <div class="misc-pages">
              <router-link
                v-for="nav in miscPages"
                :key="`main_page_nav_${nav.label}`"
                :to="nav.path">
                <svg-icon
                  :filepath="nav.icon"
                  width="24px"
                  height="24px"
                  stroke="#267fff"/>
                <span>
                  {{ nav.label }}
                </span>
              </router-link>
            </div>
          </div>
          <div class="column is-half">
            <img
              class="home-welcome"
              :src="require('@images/welcome.png')">
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PAGES } from '@js/navigations'
import pageAuthorizationMixin from '@/mixins/page-authorization-mixin'

export default {
  name: 'home-page',
  mixins: [pageAuthorizationMixin],
  data() {
    return {
      mainPages: [
        PAGES.ASSIGNMENT,
        PAGES.PRINT,
        PAGES.PACK_AND_DISPATCH,
        PAGES.TASKS
      ],
      miscPages: [
        PAGES.ROSTER,
        PAGES.BILLING,
        PAGES.USERS
      ]
    }
  },
  computed: {
    ...mapGetters('sessionUser', [
      'profile'
    ]),
    userName() {
      return this.profile && this.profile.name
    }
  },
  created() {
    if (!this.profile) {
      this.getProfileRequest()
    }
  },
  methods: {
    ...mapActions('sessionUser', [
      'getProfileRequest',
      'logoutRequest'
    ]),
    logout() {
      this.logoutRequest()
    }
  }
}
</script>

<style lang="scss" scoped>
.home-page {
  min-width: 824px;

  .container {
    @include touch {
      padding-right: $space-m;
      padding-left: $space-m;
    }

    .logout-link {
      position: absolute;
      top: 12px;
      right: 0;
      padding: $space-m 0;
      color: $primary-ink;

      @include touch {
        padding-right: $space-m;
      }
    }

    > .columns {
      align-items: center;
      min-height: 95vh;

      .logo {
        display: block;
        width: 48px;
        margin-top: $space-m;
        margin-bottom: $space-l;
      }

      .home-header {
        margin-bottom: $space-xxs;
        @extend %display_large;
      }

      .home-message {
        @extend %display_light;
      }

      .home-welcome {
        width: 100%;
        max-width: 549px;
        max-height: 490px;
        margin: $space-m 0;
      }

      .main-pages,
      .misc-pages {
        max-width: 400px;
        margin: $space-xl 0;

        .button,
        a {
          width: 180px;
          @extend %body;

          &:nth-child(2n - 1) {
            margin-right: $space-l;
          }

          &:nth-last-child(n + 3) {
            margin-bottom: $space-m;
          }
        }
      }

      .main-pages {
        .button {
          height: 48px;
          font-weight: bold;
        }
      }

      .misc-pages {
        a {
          display: inline-flex;
          align-items: center;

          span:not(.svg-icon) {
            margin-left: $space-xs;
            color: $primary-ink;
          }
        }
      }
    }
  }
}
</style>
